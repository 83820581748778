import * as React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { Container } from "react-bootstrap"
import Layout from "@components/Layout/Layout"
import Seo from "../components/seo"
import Breadcrumbs from "@components/Breadcrumbs/Breadcrumbs"
import CTALink from "@components/elements/CTALink"
import { capitalize, isEmpty } from "lodash"
import { generatePropertyURL, offplanResultsURL, propertiesRentURL, propertiesSaleURL, propertyDetailRentURL, propertyDetailSaleURL } from "@lib/urls"
import { usePropertyStore } from "@components/SearchResults/store/propertyStore"
import { resiPropertyTypeOptions } from "@components/SearchResults/SearchFilters/filterOptions"
import { parseHTML, slugToTitle } from "@lib/utils"
import Pagination from "@components/Pagination/Pagination"
import usePagination from "@hooks/usePagination"
import GGFXImage from "@components/elements/GGFXImage"
import areas from './../../static/areas.json';
import offplanAreas from './../../static/offplan_areas.json';
const Sitemap = () => {
  const data = useStaticQuery(graphql`
  query {
    strapiGlobalMenu {
      header_links {
        id
        # content_cta {
        #   ...contentCtaFragment
        # }
        main_menu {
          ...ctaFragment
        }
        sub_menu_cta {
          ...ctaFragment
        }
      }
    }
    strapiSitemapPage {
      image {
        url
      }
      title
      content {
        data {
          content
        }
      }
              ggfx_results {
        ...STRAPI_GGFX_RESULTFragment
      }
      strapi_id
      add {
        id
        # content_cta {
        #   ...contentCtaFragment
        # }
        main_menu {
          ...ctaFragment
        }
        sub_menu_cta {
            ...ctaFragment
          }
      }
    }
    allProjects(
      filter: { publish: { eq: true } }
      sort: { fields: createdAt, order: DESC }
    ) {
      nodes {
        ...offplanCardFragment
      }
    }
   
    allStrapiPage(filter: {publish: {eq: true}}) {
      nodes {
        page_name
        menu {
          slug
          strapi_parent {
            slug
          }
        }
      }
    }
    sales: allProperties(
    filter: {search_type: {eq: "sales"}, publish: { eq: true }}
    sort: { fields: createdAt, order: DESC }
    ) {
      nodes {
        slug
        strapi_id
        search_type
      }
    }
    lettings: allProperties(
    filter: {search_type: {eq: "lettings"}, publish: { eq: true }}
    sort: { fields: createdAt, order: DESC }
    ) {
      nodes {
        slug
        strapi_id
        search_type
      }
    }
    allStrapiTeam(
      filter: { publish: { eq: true }, show_on_website: {eq: true} }
      sort: { fields: rank, order: ASC }
    ) {
      nodes {
        slug
        name
      }
    }
    allStrapiOffice(filter: { publish: { eq: true } }) {
      nodes {
        title
        slug
      }
    }

    allStrapiAreaGuide(
      filter: { publish: { eq: true } }
      sort: { fields: rank, order: ASC }
    ) {
      nodes {
        slug
        title
      }
    }

    allStrapiBlog(
      filter: { publish: { eq: true } }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        title
        slug
      }
    }

   
    allStrapiCareer(filter: { publish: { eq: true } }) {
      nodes {
        title
        slug
      }
    }

  }
`)
const { header_links } = data.strapiGlobalMenu

  const offplans = data?.allProjects?.nodes
  const buildingTypes = []
  offplans?.forEach((offplan) => {
    offplan?.building_type?.forEach((buildingType) => {
      if (!buildingTypes.includes(buildingType))
        buildingTypes.push(buildingType)
    })
  })

  const buildingTypeOptions = [
    {
      label: "All Types",
      value: "",
    },
    ...buildingTypes.map((buildingType) => ({
      label: capitalize(slugToTitle(buildingType)),
      value: buildingType.toLowerCase().replace(/\s/g, "-"),
    })),
  ]
  const [offplanJsondata, setoffplanJsondata] = React.useState([]);

  const [salesJsondata, setSalesJsondata] = React.useState([]);
  const [lettingsJsondata, setLettingsJsondata] = React.useState([]);

  const areaJSON = usePropertyStore((state) => state.areaJSON)
  const offplanJSON = usePropertyStore((state) => state.offplanJSON)
  const setAreaJSON = usePropertyStore((state) => state.setAreaJSON)
  const setOffplanJSON = usePropertyStore((state) => state.setOffplanJSON)
  React.useEffect(() => {
    const filtered = offplanAreas.filter(item => item.type !== 'developer');
    setAreaJSON(areas)
    setOffplanJSON(filtered)
    areadatapaginationProps.setCurrentPage(1)
    teamdatapaginationProps.setCurrentPage(1)
    blogdatapaginationProps.setCurrentPage(1)
    projectspaginationProps.setCurrentPage(1)
    projectsresultspaginationProps.setCurrentPage(1)
    propertiessalespaginationProps.setCurrentPage(1)
    propertieslettingspaginationProps.setCurrentPage(1)
    propertiessaleslistingpaginationProps.setCurrentPage(1)
    propertieslettingslistingpaginationProps.setCurrentPage(1)
  }, [])

  React.useEffect(() => {
    let letoffplanJsondata = []
    buildingTypeOptions.forEach((building) => {
      if (building.value != "") {
        const buildingURI = offplanResultsURL + "type-" + building.value

        letoffplanJsondata.push({
          label: "Off plan " + capitalize(building?.label) + " for sale in Dubai",
          url: `${buildingURI}/`,

        }
        )
      }
    })
    offplanJSON?.forEach((area) => {
      let uriStr = ""
      if (area.slug != "") {
        uriStr = offplanResultsURL + "in-" + area.slug

        letoffplanJsondata.push({
          label: "Off plan properties for sale in " + capitalize(area?.slug?.replaceAll(/-/g, " ")),
          url: `${offplanResultsURL + "in-" + area?.slug + "/"}`,
        }
        )

        buildingTypeOptions.forEach((building) => {
          if (building.value != "") {
            const buildingURI = uriStr + "/type-" + building.value

            letoffplanJsondata.push({
              label: "Off plan " + capitalize(building?.label) + " for sale in " + capitalize(area?.slug?.replaceAll(/-/g, " ")),
              url: `${buildingURI}/`,

            }
            )
          }
        })

      }
    })
    setoffplanJsondata(letoffplanJsondata)

  }, [offplanJSON])


  React.useEffect(() => {
    let letsalesJsondata = []
    resiPropertyTypeOptions.forEach((building) => {
      if (building.value != "") {
        const buildingURI =
          generatePropertyURL({
            searchType: "for-sale",
            buildingType: building.value,
          })

        letsalesJsondata.push({
          label: capitalize(building.label) + " for sale in Dubai",
          url: `${buildingURI}`,

        }
        )
      }
    })

    areaJSON?.forEach((area) => {
      let uriStr = ""
      if (area.slug != "") {
        uriStr =
          generatePropertyURL({
            searchType: "for-sale",
          }) +
          "in-" +
          area.slug

        letsalesJsondata.push({
          label: "Properties for sale in " + capitalize(area.slug?.replaceAll(/-/g, " ")),
          url: `${propertiesSaleURL + "in-" + area.slug + "/"}`,
        }
        )

        resiPropertyTypeOptions.forEach((building) => {
          if (building.value != "") {
            const buildingURI =
              generatePropertyURL({
                searchType: "for-sale",
                buildingType: building.value,
              }) +
              "in-" +
              area.slug

            letsalesJsondata.push({
              label: capitalize(building.label) + " for sale in " + capitalize(area.slug?.replaceAll(/-/g, " ")),
              url: `${buildingURI}/`,

            }
            )
          }
        })

      }
    })
    setSalesJsondata(letsalesJsondata)

    let letlettingsJsondata = []
    resiPropertyTypeOptions.forEach((building) => {
      if (building.value != "") {
        const buildingURI =
          generatePropertyURL({
            searchType: "for-rent",
            buildingType: building.value,
          })

        letlettingsJsondata.push({
          label: capitalize(building.label) + " for rent in Dubai",
          url: `${buildingURI}`,

        }
        )
      }
    })
    areaJSON?.forEach((area) => {
      let uriStr = ""
      if (area.slug != "") {
        uriStr =
          generatePropertyURL({
            searchType: "for-rent",
          }) +
          "in-" +
          area.slug

        letlettingsJsondata.push({
          label: "Properties for rent in " + capitalize(area.slug?.replaceAll(/-/g, " ")),
          url: `${propertiesRentURL + "in-" + area.slug + "/"}`,
        }
        )

        resiPropertyTypeOptions.forEach((building) => {
          if (building.value != "") {
            const buildingURI =
              generatePropertyURL({
                searchType: "for-rent",
                buildingType: building.value,
              }) +
              "in-" +
              area.slug

            letlettingsJsondata.push({
              label: capitalize(building.label) + " for rent in " + capitalize(area.slug?.replaceAll(/-/g, " ")),
              url: `${buildingURI}/`,

            }
            )
          }
        })

      }
    })
    setLettingsJsondata(letlettingsJsondata)

  }, [areaJSON])
  const { add, title, content, image, strapi_id, ggfx_results } = data.strapiSitemapPage
  const lettings = data.lettings.nodes
  const sales = data.sales.nodes
  const blogdata = data?.allStrapiBlog?.nodes
  const teamdata = data?.allStrapiTeam?.nodes
  const areadata = data?.allStrapiAreaGuide?.nodes
  const officedata = data?.allStrapiOffice?.nodes
  const offplandata = data?.allProjects?.nodes
  const careersdata = data?.allStrapiCareer?.nodes
  const pagesdata = data?.allStrapiPage?.nodes

  const breadcrumbURLs = [
    {
      url: "",
      label: "Sitemap",
    },
  ]

  const blogdatapaginationProps = usePagination({
    items: blogdata,
    itemsPerPage: 30,
  })
  const teamdatapaginationProps = usePagination({
    items: teamdata,
    itemsPerPage: 30,
  })
  const areadatapaginationProps = usePagination({
    items: areadata,
    itemsPerPage: 30,
  })
  const projectspaginationProps = usePagination({
    items: offplandata,
    itemsPerPage: 30,
  })
  const projectsresultspaginationProps = usePagination({
    items: offplanJsondata,
    itemsPerPage: 30,
  })
  const propertiessalespaginationProps = usePagination({
    items: sales,
    itemsPerPage: 30,
  })
  const propertieslettingspaginationProps = usePagination({
    items: lettings,
    itemsPerPage: 30,
  })
  const propertiessaleslistingpaginationProps = usePagination({
    items: salesJsondata,
    itemsPerPage: 30,
  })
  const propertieslettingslistingpaginationProps = usePagination({
    items: lettingsJsondata,
    itemsPerPage: 30,
  })

  return (
    <Layout
      pageData={{
        page_class: "sitemap-page-wrap",
        layout: "sitemap",
      }}
    >
      <Breadcrumbs staticURLs={breadcrumbURLs} />
      <Container className="sitemap-page">
        <div className="sitemap-banner">
        <div className="bg-section">
        {/* <img onContextMenu={(e) => e.preventDefault()} draggable="false" src={bannerData?.banner_image?.url} alt="banner-bg" /> */}
        <GGFXImage
          ImageSrc={image}
          altText="banner-bg"
          imagetransforms={ggfx_results}
          renderer="srcSet"
          imagename={"sitemap-page.image.banner_img"}
          strapiID={strapi_id}
        />
        <div className="overlay" />
      </div>
      <div className="content">
      <h1 className="title">{title}</h1>
        <div className="description">
          {parseHTML(content?.data?.content)}
        </div>

      </div>
        </div>
        <div className="sitemap-block">
        {/* <ul className="list">
            {!_.isEmpty(header_links) &&
              header_links?.slice(0, 6).map((item) => {
                const { main_menu, sub_menu, id, content_cta } = item
                return (
                  <li className="parent" key={id}>
                    <CTALink
                      key={main_menu.id}
                      cta={main_menu}
                      className="main-menu"
                      alsoLabel
                    />
                    {!_.isEmpty(sub_menu) && (
                      <div>{sub_menu.map((subItem) => {
                        const { title, ctas } = subItem
                        return (

                          <div key={subItem.id}>
                            {title && <p className="heading">{title}</p>}
                            {!_.isEmpty(ctas) && (
                              <ul className="sub">
                                {ctas.map((cta) => (
                                  cta.cta_label === "See all properties for sale"
                                    || cta.cta_label === "See All New Projects"
                                    || cta.cta_label === "See All Areas in Dubai"
                                    || cta.cta_label === "See all properties for rent" ? null :
                                    <li className="child">
                                      <CTALink
                                        key={cta.id}
                                        cta={cta}
                                        className="sub-menu-link"
                                      /></li>
                                ))}
                              </ul>
                            )}
                          </div>
                        )
                      })}

                      </div>

                    )}

                  </li>
                )
              })}
            {!_.isEmpty(header_links) &&
              header_links?.slice(6, 7)[0]?.sub_menu[0]?.ctas?.map((item) => {
                // const { cta } = item
                return (
                  <li className="parent" key={item?.id}>
                    <CTALink
                      key={item.id}
                      cta={item}
                      className="main-menu"
                      alsoLabel
                    />


                  </li>
                )
              })}

          </ul> */}
          <div className="section-bk toc">
            <h4>Table of contents:</h4>
            <ul className="sitemap-block">
              <li className="sitemap-item"><a href={"#pages"}>Pages</a></li>
              <li className="sitemap-item"><a href={"#offices"}>Offices</a></li>
              <li className="sitemap-item"><a href={"#area-guides"}>Area Guides</a></li>
              <li className="sitemap-item"><a href={"#teams"}>Teams</a></li>
              <li className="sitemap-item"><a href={"#blogs"}>Blogs</a></li>
              <li className="sitemap-item"><a href={"#careers"}>Careers</a></li>
              <li className="sitemap-item"><a href={"#projects"}>Projects</a></li>
              <li className="sitemap-item"><a href={"#projects-for-sales"}>Projects for Sales</a></li>
              <li className="sitemap-item"><a href={"#properties"}>Properties</a></li>
              <li className="sitemap-item"><a href={"#properties-for-sales"}>Properties for Sales</a></li>
              <li className="sitemap-item"><a href={"#properties-for-rent"}>Properties for Rent</a></li>
            </ul>
          </div>
          <div id="pages" className="section-bk">
            <h4><a target="_blank" href={"/"}>Pages</a></h4>
            <ul className="list">
              {!isEmpty(add) &&
                add?.map((item) => {
                  const { main_menu, sub_menu_cta, id } = item
                  return (
                    <li className="parent" key={id}>
                      <CTALink
                        key={main_menu.id}
                        cta={main_menu}
                        className="main-menu"
                        alsoLabel
                      />
                      {!isEmpty(sub_menu_cta) && (
                        <div>
                          {/* {sub_menu_cta.map((subItem) => {
                          // const { title, ctas } = subItem
                          return ( */}

                            <div >
                              {/* {title && <p className="heading">{title}</p>} */}
                              {!isEmpty(sub_menu_cta) && (
                                <ul className="sub">
                                  {sub_menu_cta.map((cta) => (
                                    cta.cta_label === "See all properties for sale"
                                      || cta.cta_label === "See All New Projects"
                                      || cta.cta_label === "See All Areas in Dubai"
                                      || cta.cta_label === "See all properties for rent" ? null :
                                      <li className="child">
                                        <CTALink
                                          key={cta.id}
                                          cta={cta}
                                          className="sub-menu-link"
                                        /></li>
                                  ))}
                                </ul>
                              )}
                            </div>
                          {/* ) */}
                        {/* })} */}

                        </div>

                      )}

                    </li>
                  )
                })}




            </ul>
          </div>


          <div id="offices" className="section-bk">
            <h4><a target="_blank" href={"/contact/"}>Offices</a></h4>
            <ul className="sitemap-block">
              {officedata?.map((item, i) => {

                return (
                  <li className="sitemap-item"><a target="_blank" href={"/contact/" + item.slug + "/"}>{item.title}</a></li>
                )
              })}
            </ul>
          </div>
          <div id="area-guides" className="section-bk">
            <h4><a target="_blank" href={"/area-guides/"}>Area Guides</a></h4>
            <ul className="sitemap-block">
              {areadatapaginationProps?.currentItems?.map((item, i) => {

                return (
                  <li className="sitemap-item"><a target="_blank" href={"/area-guides/" + item.slug + "/"}>{item.title}</a></li>
                )
              })}
            </ul>
            <Pagination stopScroll {...areadatapaginationProps} />
          </div>
          <div id="teams" className="section-bk">
            <h4><a target="_blank" href={"/team/"}>Teams</a></h4>
            <ul className="sitemap-block">
              {teamdatapaginationProps?.currentItems?.map((item, i) => {

                return (
                  <li className="sitemap-item"><a target="_blank" href={"/team/" + item.slug + "/"}>{item.name}</a></li>
                )
              })}
            </ul>
            <Pagination stopScroll {...teamdatapaginationProps} />
          </div>
          <div id="blogs" className="section-bk">
            <h4><a target="_blank" href={"/blog/"}>Blogs</a></h4>
            <ul className="sitemap-block">
              {blogdatapaginationProps?.currentItems?.map((item, i) => {

                return (
                  <li className="sitemap-item"><a target="_blank" href={"/blog/" + item.slug + "/"}>{item.title}</a></li>
                )
              })}
            </ul>
            <Pagination stopScroll {...blogdatapaginationProps} />
          </div>
          <div id="careers" className="section-bk">
            <h4><a target="_blank" href={"/careers/"}>Careers</a></h4>
            <ul className="sitemap-block">
              {careersdata?.map((item, i) => {

                return (
                  <li className="sitemap-item"><a target="_blank" href={"/careers/" + item.slug + "/"}>{item.title}</a></li>
                )
              })}
            </ul>
          </div>
          <div id="projects" className="section-bk">
            <h4><a target="_blank" href={"/new-projects/"}>Projects</a></h4>
            <ul className="sitemap-block">
              {projectspaginationProps?.currentItems?.map((item, i) => {
                return (
                  <li className="sitemap-item"><a target="_blank" href={"/new-projects/" + item.slug + "/"}>{item.title} at {item?.display_address}</a></li>
                )
              })}
            </ul>
            <Pagination stopScroll {...projectspaginationProps} />
          </div>
          <div id="projects-for-sales" className="section-bk">
            <h4><a target="_blank" href={offplanResultsURL}>Projects for Sales</a></h4>


            <ul className="sitemap-block">
              {projectsresultspaginationProps?.currentItems?.map((item, i) => {
                return (
                  <li className="sitemap-item"><a target="_blank" href={item.url}>{item.label}</a></li>
                )
              })}
            </ul>

            <Pagination stopScroll {...projectsresultspaginationProps} />

          </div>
          <div id="properties" className="section-bk">

            <h4><a target="_blank" href={"/buy/properties-for-sale/"}>Properties</a></h4>
            <h5><a target="_blank" href={propertiesSaleURL}>Sales</a></h5>
            <ul className="sitemap-block">
              {propertiessalespaginationProps?.currentItems?.map((item, i) => {
                let page_url = propertyDetailSaleURL
                return (
                  <li className="sitemap-item"><a target="_blank" href={page_url + item.slug + item.strapi_id + "/"}>{capitalize(item.slug?.replaceAll(/-/g, " "))}</a></li>
                )
              })}
            </ul>
            <Pagination stopScroll {...propertiessalespaginationProps} />

            <h5><a target="_blank" href={propertiesRentURL}>Lettings</a></h5>
            <ul className="sitemap-block">
              {propertieslettingspaginationProps?.currentItems?.map((item, i) => {
                let page_url = propertyDetailRentURL
                return (
                  <li className="sitemap-item"><a target="_blank" href={page_url + item.slug + item.strapi_id + "/"}>{capitalize(item.slug?.replaceAll(/-/g, " "))}</a></li>
                )
              })}
            </ul>
            <Pagination stopScroll {...propertieslettingspaginationProps} />
          </div>
          <div id="properties-for-sales" className="section-bk">
            <h4><a target="_blank" href={propertiesSaleURL}>Properties for Sales</a></h4>

            <ul className="sitemap-block">
              {propertiessaleslistingpaginationProps?.currentItems?.map((item, i) => {
                return (
                  <li className="sitemap-item"><a target="_blank" href={item.url}>{item.label}</a></li>
                )
              })}
            </ul>
            <Pagination stopScroll {...propertiessaleslistingpaginationProps} />

            <h4 className="scroll-f" id="properties-for-rent"><a target="_blank" href={propertiesRentURL}>Properties for Rent</a></h4>

            <ul className="sitemap-block">
              {propertieslettingslistingpaginationProps?.currentItems?.map((item, i) => {
                return (
                  <li className="sitemap-item"><a target="_blank" href={item.url}>{item.label}</a></li>
                )
              })}
            </ul>
            <Pagination stopScroll {...propertieslettingslistingpaginationProps} />
          </div>



        </div>
      </Container>
    </Layout>
  )
}

export const Head = () => <Seo customCanonical="https://www.providentestate.com/sitemap/" title="Sitemap" />

export default Sitemap
